import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

import HttpRequestType from '../api/enums/EnumHttpRequestType';

import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TipoRisorseService {


  constructor( private _api: ApiService ) {  } 
  
  /*
  * GET
  */
  public listaTipoRisorsa(  fnSuccess: any, fnError: any ) : Subscription {
    return this._api.sendRequest(HttpRequestType.GET, '/api/TipoRisorsa/Lista' ).subscribe( fnSuccess, fnError );
  }
  



}
 