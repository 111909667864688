import { NgModule } from '@angular/core';
import { LinebreakPipe } from './linebreak.pipe';
import { SumPipe } from './sum.pipe';
import { FormatHTMLPipe } from './bold.pipe';

@NgModule({
  declarations: [
    SumPipe,
    LinebreakPipe,
    FormatHTMLPipe
  ],
  exports: [
    SumPipe,
    FormatHTMLPipe,
    LinebreakPipe
  ],
  imports: [
  ],
})
export class PipeModule { }
