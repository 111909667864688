import PaginationQuery from './PaginationQuery';

export class TaskQuery extends PaginationQuery {
    public ID: number;
    public InReperibilita: number;
    public DataEsecuzioneRichiesta: Date;
    public Luogo_Descrizione: string;
    public Luogo_Indirizzo: string;
    public Luogo_Localita: string;
    public Luogo_Citta: string;
    public Luogo_CAP: string;
    public Luogo_PR: string;
    public Referente: string;
    public Telefono: string;
    public NoteLogistiche: string;
    public NoteAttrezzature: string;
    public Descrizione_Attivita: string;
    public AllColumns: string;
    public IsTemplate?: boolean;
    public Cancellato?: boolean;

    constructor() {
        super();
        this.Rows   = 8;
        this.Offset = 0;
    }
}
