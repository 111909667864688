import { Injectable } from '@angular/core';

import { DirittoModel } from '../../shared/class/models/DirittoModel'

@Injectable({
  providedIn: 'root'
})
export class RoleManagerService {

  private _roles    : DirittoModel[];
  private _settings : DirittoModel[];

  constructor() { 


  }

  /*
  *
  */
  public setRoles = ( roles : DirittoModel[] ) : void => { 
    this._roles = roles; localStorage.setItem("xxROLESxx", JSON.stringify( roles ) ) 
  }


  /*
  *
  */
  public getRoles = (  ) : DirittoModel[] => this._roles;

  /*
  *
  */
  public refreshFromLocalStorage ( ) : void {
    const string = localStorage.getItem("xxROLESxx")
    try{
      this._roles = JSON.parse( string )
    } catch( e ){
      console.warn( 'Non sono presenti diritti per questo utente' );
    }
  }










  /*
  *
  */
  public isQualified ( idRole : number ) : Boolean {
  
    const _fn = ( r : DirittoModel ) : Boolean =>  r.ID === idRole;

    return ( this._roles || [] ).some(_fn);

  }


  /*
  *
  */
  public setSettings = ( value : any ) : void => { localStorage.setItem("Impostazioni", value ) }

  /*
  *
  */
  public getSettings = (  ) : any => JSON.parse(  localStorage.getItem("Impostazioni")  );


  
}
