
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linebreak'
})
export class LinebreakPipe implements PipeTransform {

  transform(value: string, ...args: string[]): string {
    
    return value.replace(/§/gi,'<br>').replace(/ /g,'&nbsp;');
    
  }

}
