
import {DirittoModel} from '../models/DirittoModel';


export class UtenteQuery {


    constructor(
        public ID       : number = null ,
        public UserName : string = null,
        public Nome     : string = null,
        public Cognome  : string = null,
        public Email    : string = null,
        public Rows     : number = 12,
        public Offset   : number = 0,
        public AllString:string  = null

    ){
        
    }



}