import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sum'
})
export class SumPipe implements PipeTransform {
    transform(items: any[], attr: string): any {
      var array : number[] = [];
      items.forEach((element, index)=>{ array[index] = parseInt(element.Num)})
      return array.reduce((a: number, b: number) => a + b, 0);
    }
}
