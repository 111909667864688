

export class RisorsePerUtenteQuery {
    constructor(
        public IDRisorsa: number = null,
        public AllString: string = null,
        public Offset: number = 0  ,
        public Rows: number = 12 ,
        public IDUtente: number | null = null
    ) {}
}

