
export default class RisorsaModel {
    public ID: number;
    public Descrizione: string;

    public VisibileInCalendario: boolean;

    public IDTipoRisorsa: number;
    public TipoRisorsa?: string;

    public IDUtente?: number;
    public Utente?: string;
    public IsRisorsaUmana?: boolean;

    public offsetLeft?: any;
    public IsCapoMissione?: any;

    public CodContabile?: string;
    public CodRisorsa?: string;

    public style?: any;

    public Indirizzo: any;
    public Qualifiche: any;
    public Specifiche: any;
}

