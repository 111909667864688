import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatHTML'
  })
  export class FormatHTMLPipe implements PipeTransform {
    transform(value: string): string {
      let regex = new RegExp(/([*])(?:(?=(\\?))\2.)*?\1/g, 'gi');
      let str = value.replace(regex, (match) => `<b>${match.replace(/\*/g,'')}</b>`);
      regex = new RegExp(/([_])(?:(?=(\\?))\2.)*?\1/g, 'gi');
      str = str.replace(regex, (match) => `<i>${match.replace(/\_/g,'')}</i>`);
      regex = new RegExp(/([%])(?:(?=(\\?))\2.)*?\1/g, 'gi');
      str = str.replace(regex, (match) => `<u>${match.replace(/\%/g,'')}</u>`);
      return str;
    }
  }
  